<template>
   <v-layout align-start>
      <v-flex
         xs12
         :style="$vuetify.breakpoint.smAndUp ? 'margin: 0px 10%' : ''"
      >
         <v-snackbar
            v-model="snackbar"
            :color="snackColor"
            :right="true"
            :bottom="true"
            :timeout="4000"
         >
            {{ snackText }}
            <template #action="{ attrs }">
               <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
               >
                  Cerrar
               </v-btn>
            </template>
         </v-snackbar>
         <v-container
            fluid
            grid-list-lg
            style="height=10px;"
            class="white mt-1"
         >
            <v-layout wrap>
               <v-flex xs12>
                  <!-- TOOLBAR -->
                  <v-toolbar flat color="white" class="pt-2">
                     <v-toolbar-title style="font-weight: 500"
                        >Talonario</v-toolbar-title
                     >
                     <v-divider class="mx-2" inset vertical></v-divider>
                     <div style="width: 150px">
                        <v-select
                           v-model="selectCiclo"
                           class="mt-6"
                           :items="cmbCiclo"
                           color="primary"
                           item-text="nombre"
                           item-value="cicloId"
                           label="Seleccionar Ciclo"
                           placeholder="Seleccionar..."
                        ></v-select>
                     </div>
                     <v-spacer></v-spacer>
                  </v-toolbar>
               </v-flex>
            </v-layout>
         </v-container>
         <v-dialog v-model="dialogPago" eager persistent max-width="900px">
            <v-form>
               <input
                  id="TokenSerfinsa"
                  type="hidden"
                  value="6f0776e1-d197-4427-9535-6948d7e17c9f"
               />
               <input
                  id="IdTransaccion"
                  type="hidden"
                  :value="'unicaes' + makeid(5)"
               />
               <input
                  id="MontroTransaccion"
                  type="hidden"
                  :value="totalPagar"
               />
               <v-card>
                  <v-card-text class="text--primary" style="margin-top: -20px">
                     <v-container grid-list-md>
                        <v-layout wrap>
                           <v-flex xs12 sm6
                              ><v-flex xs12>
                                 <span class="text-h5 font-weight-medium"
                                    >Pago de cuota {{ numeroCuota }}</span
                                 >
                              </v-flex>
                           </v-flex>
                           <v-flex xs12 sm12>
                              <v-flex xs12 class="text-h5 font-weight-medium">
                                 Detalles de pago
                              </v-flex>
                              <v-card class="borderDetallesPrimary mt-2">
                                 <v-container>
                                    <v-layout wrap>
                                       <v-flex xs12>
                                          <v-data-table
                                             hide-default-footer
                                             :items-per-page="-1"
                                             :footer-props="{
                                                itemsPerPageOptions: [-1],
                                                itemsPerPageText:
                                                   'Filas por página:'
                                             }"
                                             :headers="headersDetallePago"
                                             :items="detallesPago"
                                          >
                                          </v-data-table>
                                       </v-flex>
                                    </v-layout>
                                 </v-container>
                              </v-card>
                              <v-flex
                                 xs12
                                 class="font-weight-medium text-h6 text-center mt-1"
                              >
                                 Total a pagar: $ {{ totalPagar }}
                              </v-flex>
                           </v-flex>
                        </v-layout>
                     </v-container>
                  </v-card-text>
                  <v-card-actions
                     style="
                        margin-top: 40px;
                        margin-left: 35%;
                        padding-bottom: 20px;
                     "
                  >
                     <v-btn color="gray" text @click.native="closePago"
                        >Cancelar</v-btn
                     >
                     <a id="btPagar" href="#">
                        <img
                           src="https://test.serfinsacheckout.com:8080/img/btnpagar.png"
                        />
                     </a>
                  </v-card-actions>
               </v-card>
            </v-form>
         </v-dialog>

         <v-container fluid grid-list-lg class="white pt-0 mt-1">
            <v-form>
               <v-flex xs12>
                  <!-- INFORMACION talonario -->
                  <v-layout wrap class="pl-4 pr-4">
                     <v-flex xs12 sm2 class="smallForm">
                        <v-text-field
                           v-model="numeroTalonario"
                           label="Número de talonario"
                           color="primary"
                           readonly
                        ></v-text-field>
                     </v-flex>
                     <v-flex xs12 sm3 class="smallForm">
                        <v-text-field
                           v-model="carnet"
                           color="primary"
                           label="Carnet"
                           readonly
                        ></v-text-field>
                     </v-flex>
                     <v-flex xs12 sm5 class="smallForm">
                        <v-text-field
                           v-model="alumno"
                           label="Alumno"
                           color="primary"
                           readonly
                        ></v-text-field>
                     </v-flex>
                     <v-flex xs12 sm2 class="smallForm">
                        <CustomDatePicker
                           :date="fecha"
                           readonly
                           label="Fecha Emisión"
                           min="2015-01"
                           @updateFecha="fecha = $event"
                        />
                     </v-flex>
                     <v-flex xs12 sm2 class="smallForm">
                        <v-text-field
                           v-model="estadoTalonario"
                           label="Estado"
                           color="primary"
                           readonly
                        ></v-text-field>
                     </v-flex>
                     <v-flex xs12 sm3 class="smallForm">
                        <v-text-field
                           v-model="totalTalonario"
                           readonly
                           label="Total talonario"
                           color="primary"
                        ></v-text-field>
                     </v-flex>
                     <v-flex xs12 sm2 class="smallForm">
                        <v-text-field
                           v-model="cicloDescripcion"
                           readonly
                           color="primary"
                           label="Ciclo"
                        ></v-text-field>
                     </v-flex>
                  </v-layout>
               </v-flex>
               <v-flex xs12>
                  <v-card class="borderDetallesPrimary">
                     <v-card-text class="text--primary">
                        <v-layout>
                           <v-flex xs12>
                              <span class="text-h6">Cuotas de talonario</span>
                           </v-flex>
                        </v-layout>
                        <v-layout wrap>
                           <!-- DATATABLE DETALLES DE talonario -->
                           <v-flex xs12 sm12 style="margin-top: -20px">
                              <v-data-table
                                 :headers="headersDetalle"
                                 :items="detalles"
                                 :footer-props="{
                                    itemsPerPageOptions: [10, 25, 50],
                                    itemsPerPageText: 'Filas por página:'
                                 }"
                                 hidden-actions
                              >
                                 <template #item.opciones="{ item }">
                                    <v-btn
                                       text
                                       small
                                       fab
                                       color="primary"
                                       :disabled="
                                          disbled(item) || item.estadoId == 6
                                       "
                                       @click="showPago(item)"
                                    >
                                       <v-icon>far fa-credit-card</v-icon>
                                    </v-btn>
                                    <v-btn
                                       text
                                       small
                                       fab
                                       color="orange"
                                       :disabled="disbled(item)"
                                       @click="
                                          generarReporteTalonario(
                                             $vuetify.breakpoint.smAndUp
                                                ? 'open'
                                                : 'save',
                                             false,
                                             item.numeroCuota.toString()
                                          )
                                       "
                                    >
                                       <v-icon>fas fa-print</v-icon>
                                    </v-btn>
                                 </template>
                                 <template #no-data>
                                    <h3>Talonarios sin cuotas</h3>
                                 </template>
                                 <template #item.estado="{ item }">
                                    <v-chip :color="getColorEstado(item)" dark>
                                       <span>{{ item.estado }}</span>
                                    </v-chip>
                                 </template>
                              </v-data-table>
                           </v-flex>
                        </v-layout>
                     </v-card-text>
                  </v-card>
               </v-flex>
               <v-flex xs12>
                  <v-layout style="margin-top: 20px">
                     <!-- OPCIONES talonario -->
                     <v-btn
                        v-if="isAdmin"
                        color="primary"
                        class="ml-2"
                        @click="ocultarNuevo()"
                        >Salir</v-btn
                     >
                  </v-layout>
               </v-flex>
            </v-form>
         </v-container>
      </v-flex>
   </v-layout>
</template>

<script>
import axios from 'axios'
import formatters from '../mixins/formattersLib'
import { Base64 } from 'js-base64'
import { mask } from 'vue-the-mask'
import { authComputed } from '../helpers'
import jQuery from 'jquery'
const CustomDatePicker = () => import('./custom/CustomDatePicker')
const getReporteLib = () => import('../mixins/reporteLib')

// Definiendo variables
const $ = jQuery
window.$ = $
window.jQuery = $

export default {
   name: 'TalonarioAlumno',
   components: {
      CustomDatePicker
   },
   directives: {
      mask
   },
   mixins: [formatters],

   data: (vm) => ({
      // Variables
      mapState: window.mapState,
      dialogPago: false,
      editedIndex: -1,
      headersDetallePago: [
         {
            text: 'Nombre',
            value: 'nombre',
            class: 'titulo',
            sortable: false
         },
         {
            text: 'Precio',
            value: 'precio',
            class: 'titulo',
            align: 'right',
            sortable: false
         }
      ],
      headersDetalle: [
         {
            text: 'N° Cuota',
            value: 'numeroCuota',
            width: '20',
            class: 'titulo'
         },
         {
            text: 'A Pagar',
            value: 'totalPagar',
            class: 'titulo',
            align: 'right'
         },
         {
            text: 'Fecha de pago',
            value: 'fechaPago',
            class: 'titulo',
            align: 'right'
         },
         {
            text: 'Fecha de vencimiento',
            value: 'fechaVencimiento',
            class: 'titulo',
            align: 'right'
         },
         {
            text: 'Pago Extemporaneo',
            value: 'totalPagarMora',
            class: 'titulo',
            align: 'right'
         },
         { text: 'NPE', value: 'npe', class: 'titulo' },
         {
            text: 'Estado',
            value: 'estado',
            class: 'titulo',
            align: 'right'
         },
         {
            text: 'Opciones',
            value: 'opciones',
            sortable: false,
            width: '200',
            align: 'right',
            class: 'titulo'
         }
      ],

      // Variables de formularios
      dialogDemoTalonario: false,
      dialogPagoCuota: false,
      dialogReporteLoading: false,
      btnDetalleColor: 'primary white--text',
      talonario: [],
      talonarios: [],
      detalleIcon: 'fa-plus',
      dialogEliminarTalonario: false,
      dialogEliminarDetalle: false,
      fecha: vm.toISOLocal(new Date()).substr(0, 10),
      fechaPago: '',
      fechaAnulacion: vm.toISOLocal(new Date()).substr(0, 10),
      radiosBusqueda: 'r-numeroTalonario',
      radiosOption: 'r-individual',
      search: '',
      show: false,
      clienteId: null,

      // Propiedades talonarios
      cmbEstadoTalonario: [],

      // Talonario
      talonarioId: '',
      selectSede: '',
      cmbSede: [],
      alumno: '',
      carnet: '',
      configuracionPago: '',
      totalTalonario: '',
      numeroTalonario: '',
      cicloDescripcion: '',
      estadoTalonario: 0,
      selectCarrera: '',
      cmbCarrera: [],
      selectDecanato: '',
      cmbDecanato: [],
      selectConfPago: '',
      cmbConfPago: [],
      selectCiclo2: '',
      cmbCiclo2: [],

      // Ciclos
      selectCiclo: '',
      cmbCiclo: [],

      // Variables detalles de talonario
      detalleTalonarioId: '',
      detalles: [],
      detallesPago: [],
      totalPagar: '',
      totalMora: '',
      npe: '',
      numeroCuota: '',
      selectEstadoDetalle: '',
      cmbEstadoDetalle: '',
      filtroCuotas: '',

      // Otros
      read: 0,
      result: [],
      snackColor: 'primary',
      snackbar: false,
      valida: 0,
      verNuevo: 0,
      snackText: '',
      cicloNombre: '',
      ntalonario: '',
      nombrePago: '',
      carnetPago: '',
      numTarjeta: '',
      cvv: ''
   }),
   computed: {
      ...authComputed,
      // Titulo de dialog
      formTitle() {
         return this.editedIndex === -1 ? 'Nueva Bodega' : 'Editar Bodega'
      }
   },
   watch: {
      selectCiclo() {
         this.clear()
         this.detalles = []
         this.getTalonario()
      },
      'mapState.callBackPago'(value) {
         if (value) {
            console.log('DATOS RECIBIDOS' + value)
         }
      }
   },
   mounted() {
      // Agregando Serfinsa Pay
      let serfinsaScript = document.createElement('script')
      serfinsaScript.setAttribute(
         'src',
         'https://test.serfinsacheckout.com:8080/Scripts/Serfinsa.Pay.js'
      )

      document.head.appendChild(serfinsaScript)
   },
   created() {
      // Obteniendo imagenes base64 para impresiones
      this.toDataURL('/assets/logo_u_black-300.png').then((result) => {
         this.logo64 = result
      })
      this.toDataURL('/assets/CdA_black-300.png').then((result) => {
         this.cda64 = result
      })
      this.toDataURL('/assets/sello-cancelado-red-400.png').then((result) => {
         this.selloCancelado = result
      })
      this.toDataURL('/assets/empty-400.png').then((result) => {
         this.emptyImage = result
      })
      // Al cargar el componente se carga las detalles
      this.listarComboboxes()
   },
   methods: {
      // Generar id para pruebas de transaccion
      makeid(length) {
         var result = ''
         var characters =
            'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
         var charactersLength = characters.length
         for (var i = 0; i < length; i++) {
            result += characters.charAt(
               Math.floor(Math.random() * charactersLength)
            )
         }
         return result
      },
      // Autoseleccionar la busqueda al cambiar filtro de busquedad
      focusBusqueda() {
         this.$refs.txtBusqueda.focus()
      },
      generarReporteTalonario(mode, portadas = true, filtroCuotas = null) {
         var filteredDetalles = []

         // Filtrando cuotas si es solicitado
         if (filtroCuotas) {
            var res = filtroCuotas.split(',').map(function (item) {
               return item.trim()
            })
            filteredDetalles = this.detalles.filter((el) =>
               res.includes(el.numeroCuota.toString())
            )
            filteredDetalles =
               filteredDetalles.length == 0 ? this.detalles : filteredDetalles
         } else {
            filteredDetalles = this.detalles
         }

         const getDetalleAranceles = (item) => {
            var result = []
            var fS = item.aranceles.length < 5 ? 8 : 7
            item.aranceles.forEach((arancel) => {
               result.push([
                  { text: arancel.codigo, alignment: 'right', fontSize: fS },
                  { text: arancel.nombre, fontSize: fS },
                  { text: '$', fontSize: fS },
                  {
                     text: arancel.precio,
                     fontSize: fS,
                     margin: [0, 0, 35, 0],
                     alignment: 'right'
                  }
               ])
            })
            result.push([
               {
                  text: 'TOTAL A PAGAR',
                  fontSize: 10,
                  margin: [0, 0, 15, 0],
                  bold: true,
                  alignment: 'right',
                  colSpan: 2
               },
               {},
               {
                  text: '$',
                  fontSize: 10,
                  bold: true
               },
               {
                  text: item.totalPagar,
                  fontSize: 10,
                  bold: true,
                  margin: [0, 0, 35, 0],
                  alignment: 'right'
               }
            ])
            return result
         }

         const getTalonarioBody = (showPortadas) => {
            var resultPage = []

            // ------------------------------------ PORTADA ----------------------------------------
            if (showPortadas) {
               resultPage.push([
                  {
                     table: {
                        widths: ['*', 120, '*'],
                        body: [
                           [
                              {
                                 text: 'UNIVERSIDAD CATÓLICA DE EL SALVADOR',
                                 font: 'TimesNewRoman',
                                 fontSize: 20,
                                 bold: true,
                                 alignment: 'center',
                                 colSpan: 3
                              },
                              {},
                              {}
                           ],
                           [
                              {
                                 text: '_',
                                 color: 'white',
                                 fontSize: 20,
                                 colSpan: 3
                              },
                              {},
                              {}
                           ],
                           [
                              {
                                 text: 'NUESTRA MISIÓN',
                                 bold: true,
                                 italics: true,
                                 alignment: 'center',
                                 decoration: 'underline'
                              },
                              {
                                 image: this.logo64,
                                 alignment: 'center',
                                 rowSpan: 2,
                                 width: 90
                              },
                              {
                                 text: 'NUESTRA VISIÓN',
                                 bold: true,
                                 italics: true,
                                 alignment: 'center',
                                 decoration: 'underline'
                              }
                           ],
                           [
                              {
                                 text:
                                    'La formación de personas, inspirada en los principios cristianos y en los conocimientos' +
                                    ' técnicos y científicos, orientada a una constante búsqueda de la verdad y del uso del saber,' +
                                    ' para contribuir a la tutela y desarrollo de la dignidad humana y de la sociedad,' +
                                    ' mediante la investigación, docencia y la proyección social.',
                                 alignment: 'justify'
                              },
                              {},
                              {
                                 text:
                                    'Seremos una universidad con excelentes servicios de educación superior, líderes en la' +
                                    ' formación integral de la persona humana y de la sociedad, dentro de las' +
                                    ' exigencias de la verdad y del bien común.',
                                 alignment: 'justify'
                              }
                           ],
                           [
                              {
                                 text: '_',
                                 color: 'white',
                                 fontSize: 20,
                                 colSpan: 3
                              },
                              {},
                              {}
                           ],
                           [
                              {
                                 table: {
                                    widths: [150, 60, '*', 90],
                                    body: [
                                       [
                                          {},
                                          {
                                             text: 'CARNET:',
                                             bold: true
                                          },
                                          {
                                             text: this.talonario.carnet
                                          },
                                          {
                                             image: this.cda64,
                                             alignment: 'center',
                                             rowSpan: 4,
                                             width: 90
                                          }
                                       ],
                                       [
                                          {},
                                          {
                                             text: 'NOMBRE:',
                                             bold: true
                                          },
                                          {
                                             text: this.talonario.alumno
                                          },
                                          {}
                                       ],
                                       [
                                          {},
                                          {
                                             text: 'FACULTAD:',
                                             bold: true
                                          },
                                          {
                                             text: this.talonario.facultadNombre
                                          },
                                          {}
                                       ],
                                       [
                                          {},
                                          {
                                             text: 'CARRERA:',
                                             bold: true
                                          },
                                          {
                                             text: this.talonario.carreraNombre
                                          },
                                          {}
                                       ]
                                    ]
                                 },
                                 layout: 'noBorders',
                                 colSpan: 3
                              },
                              {},
                              {}
                           ],
                           [
                              {
                                 text: '_',
                                 color: 'white',
                                 fontSize: 16,
                                 colSpan: 3
                              },
                              {},
                              {}
                           ],
                           [
                              {
                                 text:
                                    'TALONARIO UNICAMENTE PARA CICLO: ' +
                                    this.talonario.ciclo,
                                 alignment: 'center',
                                 fontSize: '20',
                                 colSpan: 3
                              },
                              {},
                              {}
                           ],
                           [
                              {
                                 text: 'Talonario No. ' + this.talonario.numero,
                                 fontSize: 10,
                                 alignment: 'right',
                                 bold: true,
                                 colSpan: 3
                              },
                              {},
                              {}
                           ]
                        ]
                     },
                     layout: 'noBorders',
                     colSpan: 2
                  },
                  {}
               ])
            }

            // ------------------------------------ CUOTAS ----------------------------------------
            filteredDetalles.forEach((cuota) => {
               resultPage.push([
                  [
                     {
                        table: {
                           widths: [45, '*', '*'],
                           body: [
                              [
                                 {
                                    text: 'UNIVERSIDAD CATÓLICA\nDE EL SALVADOR',
                                    font: 'TimesNewRoman',
                                    fontSize: 10,
                                    bold: true,
                                    alignment: 'center',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 'CARNET:',
                                 {
                                    text: this.talonario.carnet,
                                    colSpan: 2
                                 },
                                 {}
                              ],
                              [
                                 'ALUMNO:',
                                 {
                                    text: this.talonario.alumno,
                                    colSpan: 2
                                 },
                                 {}
                              ],
                              [
                                 'CARRERA:',
                                 {
                                    text: this.talonario.carreraNombre,
                                    colSpan: 2
                                 },
                                 {}
                              ],
                              [
                                 'CICLO:',
                                 { text: this.talonario.ciclo },
                                 {
                                    text: [
                                       { text: 'Pago N° ' },
                                       {
                                          text: cuota.numeroCuota,
                                          fontSize: 18,
                                          bold: true
                                       }
                                    ]
                                 }
                              ],
                              [
                                 {
                                    text:
                                       'FACULTAD:\n' +
                                       this.talonario.facultadNombre,
                                    bold: true,
                                    alignment: 'center',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    table: {
                                       widths: [60, 120, 5, '*'],
                                       body: getDetalleAranceles(cuota)
                                    },
                                    layout: 'noBorders',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    text: '_',
                                    color: 'white',
                                    fontSize: 6,
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    table: {
                                       widths: [15, 150, 110],
                                       body: [
                                          [
                                             {},
                                             {
                                                text: 'FECHA VENCIMIENTO',
                                                fontSize: 10,
                                                bold: true
                                             },
                                             {
                                                text: cuota.fechaVencimiento,
                                                fontSize: 10,
                                                bold: true,
                                                margin: [0, 0, 45, 0],
                                                alignment: 'right'
                                             }
                                          ],
                                          [
                                             {},
                                             {
                                                text: 'PAGO EXTEMPORANEO',
                                                fontSize: 10,
                                                bold: true
                                             },
                                             {
                                                text: this.toFixedRegex(
                                                   Number(cuota.totalPagar) +
                                                      Number(
                                                         cuota.totalPagarMora
                                                      ),
                                                   2
                                                ),
                                                fontSize: 10,
                                                bold: true,
                                                margin: [0, 0, 45, 0],
                                                alignment: 'right'
                                             }
                                          ]
                                       ]
                                    },
                                    layout: 'noBorders',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    text: 'NPE',
                                    fontSize: 9,
                                    bold: true,
                                    alignment: 'right'
                                 },
                                 {
                                    text: cuota.npe,
                                    fontSize: 9,
                                    bold: true,
                                    colSpan: 2
                                 },
                                 {}
                              ],
                              [
                                 {
                                    text: Base64.decode(
                                       cuota.codigoDeBarrasCode128C
                                    ),
                                    font: 'CodigoDeBarra',
                                    colSpan: 3,
                                    fontSize: 10,
                                    alignment: 'center'
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    text: cuota.codigoDeBarras,
                                    alignment: 'center',
                                    fontSize: 7,
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    text:
                                       'Talonario No. ' + this.talonario.numero,
                                    bold: true,
                                    alignment: 'center',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    text: 'ALUMNO',
                                    bold: true,
                                    alignment: 'center',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              // SELLO CANCELADO
                              [
                                 {
                                    image:
                                       cuota.estadoId == 3
                                          ? this.selloCancelado
                                          : this.emptyImage,
                                    absolutePosition: {
                                       x: 72,
                                       y: cuota.numeroCuota == 1 ? 258 : 212
                                    },
                                    width: 170
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    text:
                                       cuota.estadoId == 3
                                          ? cuota.fechaPago
                                          : '',
                                    bold: true,
                                    color: '#d9001d',
                                    fontSize: 14,
                                    absolutePosition: {
                                       x: 120,
                                       y: cuota.numeroCuota == 1 ? 312 : 266
                                    }
                                 },
                                 {},
                                 {}
                              ]
                           ]
                        },
                        margin: [0, 0, 15, 0],
                        layout: 'noBorders'
                     }
                  ],
                  [
                     {
                        table: {
                           widths: [45, '*', '*'],
                           body: [
                              [
                                 {
                                    text: 'UNIVERSIDAD CATÓLICA\nDE EL SALVADOR',
                                    font: 'TimesNewRoman',
                                    fontSize: 10,
                                    bold: true,
                                    alignment: 'center',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 'CARNET:',
                                 {
                                    text: this.talonario.carnet,
                                    colSpan: 2
                                 },
                                 {}
                              ],
                              [
                                 'ALUMNO:',
                                 {
                                    text: this.talonario.alumno,
                                    colSpan: 2
                                 },
                                 {}
                              ],
                              [
                                 'CARRERA:',
                                 {
                                    text: this.talonario.carreraNombre,
                                    colSpan: 2
                                 },
                                 {}
                              ],
                              [
                                 'CICLO:',
                                 { text: this.talonario.ciclo },
                                 {
                                    text: [
                                       { text: 'Pago N° ' },
                                       {
                                          text: cuota.numeroCuota,
                                          fontSize: 18,
                                          bold: true
                                       }
                                    ]
                                 }
                              ],
                              [
                                 {
                                    text:
                                       'FACULTAD:\n' +
                                       this.talonario.facultadNombre,
                                    bold: true,
                                    alignment: 'center',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    table: {
                                       widths: [60, 120, 5, '*'],
                                       body: getDetalleAranceles(cuota)
                                    },
                                    layout: 'noBorders',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    text: '_',
                                    color: 'white',
                                    fontSize: 6,
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    table: {
                                       widths: [15, 150, 110],
                                       body: [
                                          [
                                             {},
                                             {
                                                text: 'FECHA VENCIMIENTO',
                                                fontSize: 10,
                                                bold: true
                                             },
                                             {
                                                text: cuota.fechaVencimiento,
                                                fontSize: 10,
                                                bold: true,
                                                margin: [0, 0, 45, 0],
                                                alignment: 'right'
                                             }
                                          ],
                                          [
                                             {},
                                             {
                                                text: 'PAGO EXTEMPORANEO',
                                                fontSize: 10,
                                                bold: true
                                             },
                                             {
                                                text: this.toFixedRegex(
                                                   Number(cuota.totalPagar) +
                                                      Number(
                                                         cuota.totalPagarMora
                                                      ),
                                                   2
                                                ),
                                                fontSize: 10,
                                                bold: true,
                                                margin: [0, 0, 45, 0],
                                                alignment: 'right'
                                             }
                                          ]
                                       ]
                                    },
                                    layout: 'noBorders',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    text: 'NPE',
                                    fontSize: 9,
                                    bold: true,
                                    alignment: 'right'
                                 },
                                 {
                                    text: cuota.npe,
                                    fontSize: 9,
                                    bold: true,
                                    colSpan: 2
                                 },
                                 {}
                              ],
                              [
                                 {
                                    text: Base64.decode(
                                       cuota.codigoDeBarrasCode128C
                                    ),
                                    font: 'CodigoDeBarra',
                                    colSpan: 3,
                                    fontSize: 10,
                                    alignment: 'center'
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    text: cuota.codigoDeBarras,
                                    alignment: 'center',
                                    fontSize: 7,
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    text:
                                       'Talonario No. ' + this.talonario.numero,
                                    bold: true,
                                    alignment: 'center',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    text: 'UNIVERSIDAD',
                                    bold: true,
                                    alignment: 'center',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              // SELLO CANCELADO
                              [
                                 {
                                    image:
                                       cuota.estadoId == 3
                                          ? this.selloCancelado
                                          : this.emptyImage,
                                    absolutePosition: {
                                       x: 302 + 72,
                                       y: cuota.numeroCuota == 1 ? 258 : 212
                                    },
                                    width: 170
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    text:
                                       cuota.estadoId == 3
                                          ? cuota.fechaPago
                                          : '',
                                    bold: true,
                                    color: '#d9001d',
                                    fontSize: 14,
                                    absolutePosition: {
                                       x: 302 + 120,
                                       y: cuota.numeroCuota == 1 ? 312 : 266
                                    }
                                 },
                                 {},
                                 {}
                              ]
                           ]
                        },
                        margin: [15, 0, 0, 0],
                        layout: 'noBorders'
                     }
                  ]
               ])
            })

            // ------------------------------------ REVERSO ----------------------------------------
            if (showPortadas) {
               resultPage.push([
                  {
                     table: {
                        widths: ['*', 175, '*'],
                        body: [
                           [
                              {
                                 text: 'TALONARIO DE PAGOS',
                                 font: 'TimesNewRoman',
                                 fontSize: 20,
                                 bold: true,
                                 alignment: 'center',
                                 colSpan: 3
                              },
                              {},
                              {}
                           ],
                           [
                              {
                                 text: '_',
                                 color: 'white',
                                 fontSize: 20,
                                 colSpan: 3
                              },
                              {},
                              {}
                           ],
                           [
                              {
                                 text: 'INSTRUCCIONES',
                                 bold: true,
                                 italics: true,
                                 alignment: 'center',
                                 decoration: 'underline'
                              },
                              {
                                 image: this.logo64,
                                 alignment: 'center',
                                 width: 90,
                                 rowSpan: 2
                              },
                              {
                                 text: 'RECUERDE QUE',
                                 bold: true,
                                 italics: true,
                                 alignment: 'center',
                                 decoration: 'underline'
                              }
                           ],
                           [
                              {
                                 text:
                                    '1.    Los pagos deberán ser cancelados en el Banco que la Universidad autorice para realizar' +
                                    ' los cobros de los alumnos.',
                                 alignment: 'justify'
                              },
                              {},
                              {
                                 text:
                                    '1.    El vencimiento de las cuotas está programado en su talonario de pago y en el calendario' +
                                    ' académico. (Art. 29 del reglamento de evaluación).',
                                 alignment: 'justify'
                              }
                           ],
                           [
                              {
                                 text:
                                    '2.    Es responsabilidad del alumno verificar que el cajero firme y selle los recibos' +
                                    ' correspondiente a los pagos que realice en el Banco',
                                 alignment: 'justify'
                              },
                              {
                                 text: 'UNIVERSIDAD CATÓLICA DE EL SALVADOR',
                                 font: 'TimesNewRoman',
                                 bold: true,
                                 alignment: 'center',
                                 fontSize: 13
                              },
                              {
                                 text:
                                    '2.    Las matriculas y las cuotas canceladas en fechas posteriores a el vencimiento tendrán' +
                                    ' complemento de $12.00 y $12.00 respectivamente dicha medida será aplicada por el Banco automáticamente.',
                                 alignment: 'justify'
                              }
                           ],
                           [
                              {
                                 text:
                                    '\n3.    En caso de extravío del talonario de pagos deberá de solicitar una reposición' +
                                    ' en la colecturía de la Universidad, previo a pago respectivo.',
                                 alignment: 'justify'
                              },
                              {
                                 text:
                                    'By pass a Metapán y Carretera antigua a San Salvador\n' +
                                    'PBX: (503) 2484-0600\nFax: (503) 2441-2655,\nSanta Ana, El Salvador\n' +
                                    'Web: http://www.catolica.edu.sv\nE-mail: catolica@catolica.edu.sv',
                                 alignment: 'center',
                                 bold: true,
                                 fontSize: 11
                              },
                              { text: '', alignment: 'justify' }
                           ],
                           [
                              {
                                 text: '_',
                                 color: 'white',
                                 fontSize: 20,
                                 colSpan: 3
                              },
                              {},
                              {}
                           ],
                           [
                              {
                                 text: 'Talonario No. ' + this.talonario.numero,
                                 fontSize: 10,
                                 alignment: 'right',
                                 bold: true,
                                 colSpan: 3
                              },
                              {},
                              {}
                           ]
                        ]
                     },
                     layout: 'noBorders',
                     pageBreak: 'before',
                     colSpan: 2
                  },
                  {}
               ])
            }

            return resultPage
         }

         var dd = {
            pageSize: {
               width: 612.0,
               height: 396.0
            },
            background: function () {
               return {
                  canvas: [
                     {
                        // COLOR DE PAGINA
                        type: 'rect',
                        x: 0,
                        y: 0,
                        w: 612.0,
                        h: 396.0,
                        color: '#FFF'
                     }
                  ]
               }
            },
            pageMargins: [20, 10],
            content: [
               {
                  table: {
                     heights: 390.0,
                     widths: ['*', '*'],
                     body: getTalonarioBody(portadas)
                  },
                  layout: {
                     hLineWidth: function () {
                        return 0
                     },
                     vLineWidth: function (i) {
                        return i === 1 ? 1 : 0
                     },
                     vLineColor: function () {
                        return 'gray'
                     },
                     vLineStyle: function () {
                        return { dash: { length: 6 } }
                     }
                  }
               }
            ],
            defaultStyle: {
               fontSize: 9
            }
         }

         var filename = 'Talonario.pdf'
         getReporteLib().then((res) => res.generarPDF(mode, dd, filename))
      },
      // Popular el datatable
      // Si no cargaron bien las detalles en el datatable se puede resetear
      reset() {
         this.listarComboboxes()
      },
      getTalonario() {
         let ciclo = 0
         //let year = 0
         for (let i = 0; i < this.cmbCiclo.length; i++) {
            if (this.cmbCiclo[i].cicloId == this.selectCiclo) {
               //year = me.cmbCiclo[i].annio
               ciclo = this.cmbCiclo[i].numero
            }
         }

         axios
            .get('api/Talonarios?Carnet=2015-AC-801&NumeroCiclo=' + ciclo)
            .then((response) => {
               this.talonarioId = response.data[0].talonarioId
               this.editarTalonario()
            })
            .catch((error) => {
               console.log(error)
            })
      },
      listarComboboxes() {
         axios
            .get('api/Ciclos')
            .then((response) => {
               this.cmbCiclo = response.data
               this.cmbCiclo2 = response.data
               this.selectCiclo = this.cmbCiclo[0].cicloId
            })
            .catch((error) => {
               console.log(error)
            })
         axios
            .get('api/EstadoTalonario')
            .then((response) => {
               this.cmbEstadoTalonario = response.data
            })
            .catch((error) => {
               console.log(error)
            })
         axios
            .get('api/EstadoDetalleTalonario')
            .then((response) => {
               this.cmbEstadoDetalle = response.data
            })
            .catch((error) => {
               console.log(error)
            })
      },
      // Cerrar ventana de dialogo
      close() {
         this.dialogDemoTalonario = false
         this.clear()
         setTimeout(() => {
            this.editedIndex = -1
         }, 300)
      },
      // Limpiar formularios y componentes
      clear() {
         this.talonario = []
         this.talonarioId = ''
         this.editedIndex = -1
         this.configuracionPago = ''
         this.totalTalonario = ''
         this.numeroTalonario = ''
         this.selectSede = ''
         this.selectEstadoTalonario = ''
         this.carnet = ''
         this.alumno = ''
         this.ntalonario = ''
         this.cicloNombre = ''
      },
      editarTalonario() {
         axios
            .get('api/Talonarios/' + this.talonarioId)
            .then((response) => {
               this.talonario = response.data
               this.fecha = this.parseDate(this.talonario.fecha)
               this.fechaAnulacion = this.parseDate(
                  this.talonario.fechaAnulacion
               )
               this.configuracionPago = this.talonario.configuracionPago
               this.totalTalonario = this.talonario.totalTalonario
               this.nuthisroTalonario = this.talonario.nuthisro
               this.carnet = this.talonario.carnet
               this.cicloDescripcion = this.talonario.ciclo
               this.alumno = this.talonario.alumno
               this.selectSede = this.talonario.sedeId
               this.estadoTalonario = this.talonario.estado
               this.detalles = this.talonario.detalles
            })
            .catch((error) => {
               console.log(error)
            })
      },
      getColorEstado(item) {
         switch (item.estadoId) {
            case 1:
               return 'green'
               break
            case 2:
               return 'red'
               break
            case 3:
               return 'blue'
               break

            default:
               return 'black'
               break
         }
      },
      decodeString() {
         this.encoded64 = Base64.decode(this.encoded64)
      },
      // Get base64 image
      toDataURL(url) {
         return new Promise(function (resolve) {
            var xhr = new XMLHttpRequest()
            xhr.onload = function () {
               var reader = new FileReader()
               reader.onloadend = function () {
                  resolve(reader.result)
               }
               reader.readAsDataURL(xhr.response)
            }
            xhr.open('GET', url)
            xhr.responseType = 'blob'
            xhr.send()
         })
      },
      // Generar reporte de talonario completo
      generarTalonarioCompleto(mode, portadas = true) {
         const getDetalleAranceles = (item) => {
            var result = []
            var fS = item.aranceles.length < 5 ? 8 : 7
            item.aranceles.forEach((arancel) => {
               result.push([
                  { text: arancel.codigo, alignment: 'right', fontSize: fS },
                  { text: arancel.nombre, fontSize: fS },
                  { text: '$', fontSize: fS },
                  {
                     text: arancel.precio,
                     fontSize: fS,
                     margin: [0, 0, 35, 0],
                     alignment: 'right'
                  }
               ])
            })
            result.push([
               {
                  text: 'TOTAL A PAGAR',
                  fontSize: 10,
                  margin: [0, 0, 15, 0],
                  bold: true,
                  alignment: 'right',
                  colSpan: 2
               },
               {},
               {
                  text: '$',
                  fontSize: 10,
                  bold: true
               },
               {
                  text: item.totalPagar,
                  fontSize: 10,
                  bold: true,
                  margin: [0, 0, 35, 0],
                  alignment: 'right'
               }
            ])
            return result
         }

         const getTalonarioBody = (showPortadas) => {
            var resultPage = []

            // ------------------------------------ PORTADA ----------------------------------------
            if (showPortadas) {
               resultPage.push([
                  {
                     table: {
                        widths: ['*', 120, '*'],
                        body: [
                           [
                              {
                                 text: 'UNIVERSIDAD CATÓLICA DE EL SALVADOR',
                                 font: 'TimesNewRoman',
                                 fontSize: 20,
                                 bold: true,
                                 alignment: 'center',
                                 colSpan: 3
                              },
                              {},
                              {}
                           ],
                           [
                              {
                                 text: '_',
                                 color: 'white',
                                 fontSize: 20,
                                 colSpan: 3
                              },
                              {},
                              {}
                           ],
                           [
                              {
                                 text: 'NUESTRA MISIÓN',
                                 bold: true,
                                 italics: true,
                                 alignment: 'center',
                                 decoration: 'underline'
                              },
                              {
                                 image: this.logo64,
                                 alignment: 'center',
                                 rowSpan: 2,
                                 width: 90
                              },
                              {
                                 text: 'NUESTRA VISIÓN',
                                 bold: true,
                                 italics: true,
                                 alignment: 'center',
                                 decoration: 'underline'
                              }
                           ],
                           [
                              {
                                 text:
                                    'La formación de personas, inspirada en los principios cristianos y en los conocimientos' +
                                    ' técnicos y científicos, orientada a una constante búsqueda de la verdad y del uso del saber,' +
                                    ' para contribuir a la tutela y desarrollo de la dignidad humana y de la sociedad,' +
                                    ' mediante la investigación, docencia y la proyección social.',
                                 alignment: 'justify'
                              },
                              {},
                              {
                                 text:
                                    'Seremos una universidad con excelentes servicios de educación superior, líderes en la' +
                                    ' formación integral de la persona humana y de la sociedad, dentro de las' +
                                    ' exigencias de la verdad y del bien común.',
                                 alignment: 'justify'
                              }
                           ],
                           [
                              {
                                 text: '_',
                                 color: 'white',
                                 fontSize: 20,
                                 colSpan: 3
                              },
                              {},
                              {}
                           ],
                           [
                              {
                                 table: {
                                    widths: [150, 60, '*', 90],
                                    body: [
                                       [
                                          {},
                                          {
                                             text: 'CARNET:',
                                             bold: true
                                          },
                                          {
                                             text: this.talonario.carnet
                                          },
                                          {
                                             image: this.cda64,
                                             alignment: 'center',
                                             rowSpan: 4,
                                             width: 90
                                          }
                                       ],
                                       [
                                          {},
                                          {
                                             text: 'NOMBRE:',
                                             bold: true
                                          },
                                          {
                                             text: this.talonario.cliente
                                          },
                                          {}
                                       ],
                                       [
                                          {},
                                          {
                                             text: 'FACULTAD:',
                                             bold: true
                                          },
                                          {
                                             text: this.talonario.facultad
                                          },
                                          {}
                                       ],
                                       [
                                          {},
                                          {
                                             text: 'CARRERA:',
                                             bold: true
                                          },
                                          {
                                             text: this.talonario.carrera
                                          },
                                          {}
                                       ]
                                    ]
                                 },
                                 layout: 'noBorders',
                                 colSpan: 3
                              },
                              {},
                              {}
                           ],
                           [
                              {
                                 text: '_',
                                 color: 'white',
                                 fontSize: 16,
                                 colSpan: 3
                              },
                              {},
                              {}
                           ],
                           [
                              {
                                 text:
                                    'TALONARIO UNICAMENTE PARA CICLO: ' +
                                    this.talonario.ciclo,
                                 alignment: 'center',
                                 fontSize: '20',
                                 colSpan: 3
                              },
                              {},
                              {}
                           ],
                           [
                              {
                                 text: 'Talonario No. ' + this.talonario.numero,
                                 fontSize: 10,
                                 alignment: 'right',
                                 bold: true,
                                 colSpan: 3
                              },
                              {},
                              {}
                           ]
                        ]
                     },
                     layout: 'noBorders',
                     colSpan: 2
                  },
                  {}
               ])
            }

            // ------------------------------------ CUOTAS ----------------------------------------
            this.detalles.forEach((cuota) => {
               resultPage.push([
                  [
                     {
                        table: {
                           widths: [45, '*', '*'],
                           body: [
                              [
                                 {
                                    text: 'UNIVERSIDAD CATÓLICA\nDE EL SALVADOR',
                                    font: 'TimesNewRoman',
                                    fontSize: 10,
                                    bold: true,
                                    alignment: 'center',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 'CARNET:',
                                 {
                                    text: this.talonario.carnet,
                                    colSpan: 2
                                 },
                                 {}
                              ],
                              [
                                 'ALUMNO:',
                                 {
                                    text: this.talonario.cliente,
                                    colSpan: 2
                                 },
                                 {}
                              ],
                              [
                                 'CARRERA:',
                                 {
                                    text: this.talonario.carrera,
                                    colSpan: 2
                                 },
                                 {}
                              ],
                              [
                                 'CICLO:',
                                 { text: this.talonario.ciclo },
                                 {
                                    text: [
                                       { text: 'Pago N° ' },
                                       {
                                          text: cuota.numeroCuota,
                                          fontSize: 18,
                                          bold: true
                                       }
                                    ]
                                 }
                              ],
                              [
                                 {
                                    text:
                                       'FACULTAD:\n' + this.talonario.facultad,
                                    bold: true,
                                    alignment: 'center',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    table: {
                                       widths: [60, 120, 5, '*'],
                                       body: getDetalleAranceles(cuota)
                                    },
                                    layout: 'noBorders',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    text: '_',
                                    color: 'white',
                                    fontSize: 6,
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    table: {
                                       widths: [15, 150, 110],
                                       body: [
                                          [
                                             {},
                                             {
                                                text: 'FECHA VENCIMIENTO',
                                                fontSize: 10,
                                                bold: true
                                             },
                                             {
                                                text: cuota.fechaVencimiento,
                                                fontSize: 10,
                                                bold: true,
                                                margin: [0, 0, 45, 0],
                                                alignment: 'right'
                                             }
                                          ],
                                          [
                                             {},
                                             {
                                                text: 'PAGO EXTEMPORANEO',
                                                fontSize: 10,
                                                bold: true
                                             },
                                             {
                                                text: this.toFixedRegex(
                                                   Number(cuota.totalPagar) +
                                                      Number(
                                                         cuota.totalPagarMora
                                                      ),
                                                   2
                                                ),
                                                fontSize: 10,
                                                bold: true,
                                                margin: [0, 0, 45, 0],
                                                alignment: 'right'
                                             }
                                          ]
                                       ]
                                    },
                                    layout: 'noBorders',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    text: 'NPE',
                                    fontSize: 9,
                                    bold: true,
                                    alignment: 'right'
                                 },
                                 {
                                    text: cuota.npe,
                                    fontSize: 9,
                                    bold: true,
                                    colSpan: 2
                                 },
                                 {}
                              ],
                              [
                                 {
                                    text: Base64.decode(cuota.codebar128C),
                                    font: 'CodigoDeBarra',
                                    colSpan: 3,
                                    fontSize: 10,
                                    alignment: 'center'
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    text: cuota.codebar,
                                    alignment: 'center',
                                    fontSize: 7,
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    text:
                                       'Talonario No. ' + this.talonario.numero,
                                    bold: true,
                                    alignment: 'center',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    text: 'ALUMNO',
                                    bold: true,
                                    alignment: 'center',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ]
                           ]
                        },
                        margin: [0, 0, 15, 0],
                        layout: 'noBorders'
                     }
                  ],
                  [
                     {
                        table: {
                           widths: [45, '*', '*'],
                           body: [
                              [
                                 {
                                    text: 'UNIVERSIDAD CATÓLICA\nDE EL SALVADOR',
                                    font: 'TimesNewRoman',
                                    fontSize: 10,
                                    bold: true,
                                    alignment: 'center',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 'CARNET:',
                                 {
                                    text: this.talonario.carnet,
                                    colSpan: 2
                                 },
                                 {}
                              ],
                              [
                                 'ALUMNO:',
                                 {
                                    text: this.talonario.cliente,
                                    colSpan: 2
                                 },
                                 {}
                              ],
                              [
                                 'CARRERA:',
                                 {
                                    text: this.talonario.carrera,
                                    colSpan: 2
                                 },
                                 {}
                              ],
                              [
                                 'CICLO:',
                                 { text: this.talonario.ciclo },
                                 {
                                    text: [
                                       { text: 'Pago N° ' },
                                       {
                                          text: cuota.numeroCuota,
                                          fontSize: 18,
                                          bold: true
                                       }
                                    ]
                                 }
                              ],
                              [
                                 {
                                    text:
                                       'FACULTAD:\n' + this.talonario.facultad,
                                    bold: true,
                                    alignment: 'center',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    table: {
                                       widths: [60, 120, 5, '*'],
                                       body: getDetalleAranceles(cuota)
                                    },
                                    layout: 'noBorders',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    text: '_',
                                    color: 'white',
                                    fontSize: 6,
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    table: {
                                       widths: [15, 150, 110],
                                       body: [
                                          [
                                             {},
                                             {
                                                text: 'FECHA VENCIMIENTO',
                                                fontSize: 10,
                                                bold: true
                                             },
                                             {
                                                text: cuota.fechaVencimiento,
                                                fontSize: 10,
                                                bold: true,
                                                margin: [0, 0, 45, 0],
                                                alignment: 'right'
                                             }
                                          ],
                                          [
                                             {},
                                             {
                                                text: 'PAGO EXTEMPORANEO',
                                                fontSize: 10,
                                                bold: true
                                             },
                                             {
                                                text: this.toFixedRegex(
                                                   Number(cuota.totalPagar) +
                                                      Number(
                                                         cuota.totalPagarMora
                                                      ),
                                                   2
                                                ),
                                                fontSize: 10,
                                                bold: true,
                                                margin: [0, 0, 45, 0],
                                                alignment: 'right'
                                             }
                                          ]
                                       ]
                                    },
                                    layout: 'noBorders',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    text: 'NPE',
                                    fontSize: 9,
                                    bold: true,
                                    alignment: 'right'
                                 },
                                 {
                                    text: cuota.npe,
                                    fontSize: 9,
                                    bold: true,
                                    colSpan: 2
                                 },
                                 {}
                              ],
                              [
                                 {
                                    text: Base64.decode(cuota.codebar128C),
                                    font: 'CodigoDeBarra',
                                    colSpan: 3,
                                    fontSize: 10,
                                    alignment: 'center'
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    text: cuota.codebar,
                                    alignment: 'center',
                                    fontSize: 7,
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    text:
                                       'Talonario No. ' + this.talonario.numero,
                                    bold: true,
                                    alignment: 'center',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    text: 'UNIVERSIDAD',
                                    bold: true,
                                    alignment: 'center',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ]
                           ]
                        },
                        margin: [15, 0, 0, 0],
                        layout: 'noBorders'
                     }
                  ]
               ])
            })

            // ------------------------------------ REVERSO ----------------------------------------
            if (showPortadas) {
               resultPage.push([
                  {
                     table: {
                        widths: ['*', 175, '*'],
                        body: [
                           [
                              {
                                 text: 'TALONARIO DE PAGOS',
                                 font: 'TimesNewRoman',
                                 fontSize: 20,
                                 bold: true,
                                 alignment: 'center',
                                 colSpan: 3
                              },
                              {},
                              {}
                           ],
                           [
                              {
                                 text: '_',
                                 color: 'white',
                                 fontSize: 20,
                                 colSpan: 3
                              },
                              {},
                              {}
                           ],
                           [
                              {
                                 text: 'INSTRUCCIONES',
                                 bold: true,
                                 italics: true,
                                 alignment: 'center',
                                 decoration: 'underline'
                              },
                              {
                                 image: this.logo64,
                                 alignment: 'center',
                                 width: 90,
                                 rowSpan: 2
                              },
                              {
                                 text: 'RECUERDE QUE',
                                 bold: true,
                                 italics: true,
                                 alignment: 'center',
                                 decoration: 'underline'
                              }
                           ],
                           [
                              {
                                 text:
                                    '1.    Los pagos deberán ser cancelados en el Banco que la Universidad autorice para realizar' +
                                    ' los cobros de los alumnos.',
                                 alignment: 'justify'
                              },
                              {},
                              {
                                 text:
                                    '1.    El vencimiento de las cuotas está programado en su talonario de pago y en el calendario' +
                                    ' académico. (Art. 29 del reglamento de evaluación).',
                                 alignment: 'justify'
                              }
                           ],
                           [
                              {
                                 text:
                                    '2.    Es responsabilidad del alumno verificar que el cajero firme y selle los recibos' +
                                    ' correspondiente a los pagos que realice en el Banco',
                                 alignment: 'justify'
                              },
                              {
                                 text: 'UNIVERSIDAD CATÓLICA DE EL SALVADOR',
                                 font: 'TimesNewRoman',
                                 bold: true,
                                 alignment: 'center',
                                 fontSize: 13
                              },
                              {
                                 text:
                                    '2.    Las matriculas y las cuotas canceladas en fechas posteriores a el vencimiento tendrán' +
                                    ' complemento de $12.00 y $12.00 respectivamente dicha medida será aplicada por el Banco automáticamente.',
                                 alignment: 'justify'
                              }
                           ],
                           [
                              {
                                 text:
                                    '\n3.    En caso de extravío del talonario de pagos deberá de solicitar una reposición' +
                                    ' en la colecturía de la Universidad, previo a pago respectivo.',
                                 alignment: 'justify'
                              },
                              {
                                 text:
                                    'By pass a Metapán y Carretera antigua a San Salvador\n' +
                                    'PBX: (503) 2484-0600\nFax: (503) 2441-2655,\nSanta Ana, El Salvador\n' +
                                    'Web: http://www.catolica.edu.sv\nE-mail: catolica@catolica.edu.sv',
                                 alignment: 'center',
                                 bold: true,
                                 fontSize: 11
                              },
                              { text: '', alignment: 'justify' }
                           ],
                           [
                              {
                                 text: '_',
                                 color: 'white',
                                 fontSize: 20,
                                 colSpan: 3
                              },
                              {},
                              {}
                           ],
                           [
                              {
                                 text: 'Talonario No. ' + this.talonario.numero,
                                 fontSize: 10,
                                 alignment: 'right',
                                 bold: true,
                                 colSpan: 3
                              },
                              {},
                              {}
                           ]
                        ]
                     },
                     layout: 'noBorders',
                     pageBreak: 'before',
                     colSpan: 2
                  },
                  {}
               ])
            }

            return resultPage
         }

         var dd = {
            pageSize: {
               width: 612.0,
               height: 396.0
            },
            background: function () {
               return {
                  canvas: [
                     {
                        // COLOR DE PAGINA
                        type: 'rect',
                        x: 0,
                        y: 0,
                        w: 612.0,
                        h: 396.0,
                        color: '#FFF'
                     }
                  ]
               }
            },
            pageMargins: [20, 10],
            content: [
               {
                  table: {
                     heights: 390.0,
                     widths: ['*', '*'],
                     body: getTalonarioBody(portadas)
                  },
                  layout: {
                     hLineWidth: function () {
                        return 0
                     },
                     vLineWidth: function (i) {
                        return i === 1 ? 1 : 0
                     },
                     vLineColor: function () {
                        return 'gray'
                     },
                     vLineStyle: function () {
                        return { dash: { length: 6 } }
                     }
                  }
               }
            ],
            defaultStyle: {
               fontSize: 9
            }
         }

         var filename = 'Talonario.pdf'
         getReporteLib().then((res) => res.generarPDF(mode, dd, filename))
      },
      // Generar reporte de cuota de talonario
      generarCuota(mode, item) {
         const getDetalleAranceles = (item) => {
            var result = []
            var fS = item.aranceles.length < 5 ? 8 : 7
            item.aranceles.forEach((arancel) => {
               result.push([
                  { text: arancel.codigo, alignment: 'right', fontSize: fS },
                  { text: arancel.nombre, fontSize: fS },
                  { text: '$', fontSize: fS },
                  {
                     text: arancel.precio,
                     fontSize: fS,
                     margin: [0, 0, 35, 0],
                     alignment: 'right'
                  }
               ])
            })
            result.push([
               {
                  text: 'TOTAL A PAGAR',
                  fontSize: 10,
                  margin: [0, 0, 15, 0],
                  bold: true,
                  alignment: 'right',
                  colSpan: 2
               },
               {},
               {
                  text: '$',
                  fontSize: 10,
                  bold: true
               },
               {
                  text: item.totalPagar,
                  fontSize: 10,
                  bold: true,
                  margin: [0, 0, 35, 0],
                  alignment: 'right'
               }
            ])
            return result
         }

         var dd = {
            pageSize: {
               width: 612.0,
               height: 396.0
            },
            background: function () {
               return {
                  canvas: [
                     {
                        // COLOR DE PAGINA
                        type: 'rect',
                        x: 0,
                        y: 0,
                        w: 612.0,
                        h: 396.0,
                        color: '#FFF'
                     }
                  ]
               }
            },
            pageMargins: [20, 10],
            content: [
               {
                  table: {
                     heights: 390.0,
                     widths: ['*', '*'],
                     body: [
                        // ------------------------------------ CUOTA ----------------------------------------
                        [
                           [
                              {
                                 table: {
                                    widths: [45, '*', '*'],
                                    body: [
                                       [
                                          {
                                             text: 'UNIVERSIDAD CATÓLICA\nDE EL SALVADOR',
                                             font: 'TimesNewRoman',
                                             fontSize: 10,
                                             bold: true,
                                             alignment: 'center',
                                             colSpan: 3
                                          },
                                          {},
                                          {}
                                       ],
                                       [
                                          'CARNET:',
                                          {
                                             text: this.talonario.carnet,
                                             colSpan: 2
                                          },
                                          {}
                                       ],
                                       [
                                          'ALUMNO:',
                                          {
                                             text: this.talonario.cliente,
                                             colSpan: 2
                                          },
                                          {}
                                       ],
                                       [
                                          'CARRERA:',
                                          {
                                             text: this.talonario.carrera,
                                             colSpan: 2
                                          },
                                          {}
                                       ],
                                       [
                                          'CICLO:',
                                          { text: this.talonario.ciclo },
                                          {
                                             text: [
                                                { text: 'Pago N° ' },
                                                {
                                                   text: item.numeroCuota,
                                                   fontSize: 18,
                                                   bold: true
                                                }
                                             ]
                                          }
                                       ],
                                       [
                                          {
                                             text:
                                                'FACULTAD:\n' +
                                                this.talonario.facultad,
                                             bold: true,
                                             alignment: 'center',
                                             colSpan: 3
                                          },
                                          {},
                                          {}
                                       ],
                                       [
                                          {
                                             table: {
                                                widths: [60, 120, 5, '*'],
                                                body: getDetalleAranceles(item)
                                             },
                                             layout: 'noBorders',
                                             colSpan: 3
                                          },
                                          {},
                                          {}
                                       ],
                                       [
                                          {
                                             text: '_',
                                             color: 'white',
                                             fontSize: 6,
                                             colSpan: 3
                                          },
                                          {},
                                          {}
                                       ],
                                       [
                                          {
                                             table: {
                                                widths: [15, 150, 110],
                                                body: [
                                                   [
                                                      {},
                                                      {
                                                         text: 'FECHA VENCIMIENTO',
                                                         fontSize: 10,
                                                         bold: true
                                                      },
                                                      {
                                                         text: item.fechaVencimiento,
                                                         fontSize: 10,
                                                         bold: true,
                                                         margin: [0, 0, 45, 0],
                                                         alignment: 'right'
                                                      }
                                                   ],
                                                   [
                                                      {},
                                                      {
                                                         text: 'PAGO EXTEMPORANEO',
                                                         fontSize: 10,
                                                         bold: true
                                                      },
                                                      {
                                                         text: this.toFixedRegex(
                                                            Number(
                                                               item.totalPagar
                                                            ) +
                                                               Number(
                                                                  item.totalPagarMora
                                                               ),
                                                            2
                                                         ),
                                                         fontSize: 10,
                                                         bold: true,
                                                         margin: [0, 0, 45, 0],
                                                         alignment: 'right'
                                                      }
                                                   ]
                                                ]
                                             },
                                             layout: 'noBorders',
                                             colSpan: 3
                                          },
                                          {},
                                          {}
                                       ],
                                       [
                                          {
                                             text: 'NPE',
                                             fontSize: 9,
                                             bold: true,
                                             alignment: 'right'
                                          },
                                          {
                                             text: item.npe,
                                             fontSize: 9,
                                             bold: true,
                                             colSpan: 2
                                          },
                                          {}
                                       ],
                                       [
                                          {
                                             text: Base64.decode(
                                                item.codebar128C
                                             ),
                                             font: 'CodigoDeBarra',
                                             colSpan: 3,
                                             fontSize: 10,
                                             alignment: 'center'
                                          },
                                          {},
                                          {}
                                       ],
                                       [
                                          {
                                             text: item.codebar,
                                             alignment: 'center',
                                             fontSize: 7,
                                             colSpan: 3
                                          },
                                          {},
                                          {}
                                       ],
                                       [
                                          {
                                             text:
                                                'Talonario No. ' +
                                                this.talonario.numero,
                                             bold: true,
                                             alignment: 'center',
                                             colSpan: 3
                                          },
                                          {},
                                          {}
                                       ],
                                       [
                                          {
                                             text: 'ALUMNO',
                                             bold: true,
                                             alignment: 'center',
                                             colSpan: 3
                                          },
                                          {},
                                          {}
                                       ]
                                    ]
                                 },
                                 margin: [0, 0, 15, 0],
                                 layout: 'noBorders'
                              }
                           ],
                           [
                              {
                                 table: {
                                    widths: [45, '*', '*'],
                                    body: [
                                       [
                                          {
                                             text: 'UNIVERSIDAD CATÓLICA\nDE EL SALVADOR',
                                             font: 'TimesNewRoman',
                                             fontSize: 10,
                                             bold: true,
                                             alignment: 'center',
                                             colSpan: 3
                                          },
                                          {},
                                          {}
                                       ],
                                       [
                                          'CARNET:',
                                          {
                                             text: this.talonario.carnet,
                                             colSpan: 2
                                          },
                                          {}
                                       ],
                                       [
                                          'ALUMNO:',
                                          {
                                             text: this.talonario.cliente,
                                             colSpan: 2
                                          },
                                          {}
                                       ],
                                       [
                                          'CARRERA:',
                                          {
                                             text: this.talonario.carrera,
                                             colSpan: 2
                                          },
                                          {}
                                       ],
                                       [
                                          'CICLO:',
                                          { text: this.talonario.ciclo },
                                          {
                                             text: [
                                                { text: 'Pago N° ' },
                                                {
                                                   text: item.numeroCuota,
                                                   fontSize: 18,
                                                   bold: true
                                                }
                                             ]
                                          }
                                       ],
                                       [
                                          {
                                             text:
                                                'FACULTAD:\n' +
                                                this.talonario.facultad,
                                             bold: true,
                                             alignment: 'center',
                                             colSpan: 3
                                          },
                                          {},
                                          {}
                                       ],
                                       [
                                          {
                                             table: {
                                                widths: [60, 120, 5, '*'],
                                                body: getDetalleAranceles(item)
                                             },
                                             layout: 'noBorders',
                                             colSpan: 3
                                          },
                                          {},
                                          {}
                                       ],
                                       [
                                          {
                                             text: '_',
                                             color: 'white',
                                             fontSize: 6,
                                             colSpan: 3
                                          },
                                          {},
                                          {}
                                       ],
                                       [
                                          {
                                             table: {
                                                widths: [15, 150, 110],
                                                body: [
                                                   [
                                                      {},
                                                      {
                                                         text: 'FECHA VENCIMIENTO',
                                                         fontSize: 10,
                                                         bold: true
                                                      },
                                                      {
                                                         text: item.fechaVencimiento,
                                                         fontSize: 10,
                                                         bold: true,
                                                         margin: [0, 0, 45, 0],
                                                         alignment: 'right'
                                                      }
                                                   ],
                                                   [
                                                      {},
                                                      {
                                                         text: 'PAGO EXTEMPORANEO',
                                                         fontSize: 10,
                                                         bold: true
                                                      },
                                                      {
                                                         text: this.toFixedRegex(
                                                            Number(
                                                               item.totalPagar
                                                            ) +
                                                               Number(
                                                                  item.totalPagarMora
                                                               ),
                                                            2
                                                         ),
                                                         fontSize: 10,
                                                         bold: true,
                                                         margin: [0, 0, 45, 0],
                                                         alignment: 'right'
                                                      }
                                                   ]
                                                ]
                                             },
                                             layout: 'noBorders',
                                             colSpan: 3
                                          },
                                          {},
                                          {}
                                       ],
                                       [
                                          {
                                             text: 'NPE',
                                             fontSize: 9,
                                             bold: true,
                                             alignment: 'right'
                                          },
                                          {
                                             text: item.npe,
                                             fontSize: 9,
                                             bold: true,
                                             colSpan: 2
                                          },
                                          {}
                                       ],
                                       [
                                          {
                                             text: Base64.decode(
                                                item.codebar128C
                                             ),
                                             font: 'CodigoDeBarra',
                                             colSpan: 3,
                                             fontSize: 10,
                                             alignment: 'center'
                                          },
                                          {},
                                          {}
                                       ],
                                       [
                                          {
                                             text: item.codebar,
                                             alignment: 'center',
                                             fontSize: 7,
                                             colSpan: 3
                                          },
                                          {},
                                          {}
                                       ],
                                       [
                                          {
                                             text:
                                                'Talonario No. ' +
                                                this.talonario.numero,
                                             bold: true,
                                             alignment: 'center',
                                             colSpan: 3
                                          },
                                          {},
                                          {}
                                       ],
                                       [
                                          {
                                             text: 'UNIVERSIDAD',
                                             bold: true,
                                             alignment: 'center',
                                             colSpan: 3
                                          },
                                          {},
                                          {}
                                       ]
                                    ]
                                 },
                                 margin: [15, 0, 0, 0],
                                 layout: 'noBorders'
                              }
                           ]
                        ]
                     ]
                  },
                  layout: {
                     hLineWidth: function () {
                        return 0
                     },
                     vLineWidth: function (i) {
                        return i === 1 ? 1 : 0
                     },
                     vLineColor: function () {
                        return 'gray'
                     },
                     vLineStyle: function () {
                        return { dash: { length: 6 } }
                     }
                  }
               }
            ],
            defaultStyle: {
               fontSize: 9
            }
         }

         var filename = 'Cuota.pdf'
         getReporteLib().then((res) => res.generarPDF(mode, dd, filename))
      },
      disbled(item) {
         for (let i = 0; i < this.detalles.length; i++) {
            if (
               this.detalles[i].detalleTalonarioId == item.detalleTalonarioId
            ) {
               if (
                  item.numeroCuota > 1 &&
                  (item.numeroCuota > 1
                     ? this.detalles[i - 1].estadoId != 3
                     : false)
               ) {
                  return true
               }
            }
         }
      },
      showPago(item) {
         this.numeroCuota = item.numeroCuota
         this.totalPagar = item.totalPagar
         this.detallesPago = item.aranceles
         this.detalleTalonarioId = item.detalleTalonarioId
         //this.editarDetalleTalonario()
         this.dialogPago = true
      },
      closePago() {
         this.dialogPago = false
      },
      editarDetalleTalonario() {
         axios
            .get(
               'api/colecturia/talonario/' +
                  this.talonarioId +
                  '/detalle/' +
                  this.detalleTalonarioId
            )
            .then((response) => {
               this.nombrePago = this.alumno
               this.carnetPago = this.carnet
               this.result = response.data
               this.numeroCuota = this.result.numeroCuota
               this.totalPagar = this.result.totalPagar
               this.totalMora = this.result.totalPagarMora
               this.npe = this.result.npe
            })
            .catch((error) => {
               console.log(error)
            })
      }
   }
}
</script>
